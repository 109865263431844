<template>
  <div class="content_box">
    <newTitle :id='id' type="list" txt="Facility List"></newTitle>
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <!-- 列表 -->
      <div class="main_box" v-for="(item,index) in listData" :key="index">
        <!-- 图片 -->
        <div v-if="item.image" class="img_box">
          <el-image :src="item.image" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        <div class="xiao_btn_img" v-if="item.media_data">
          <a v-for="itemA in item.media_data" :target="itemA.value == 'javascript:;' || itemA.value == '#TravelContents' || !itemA.value?'':'_blank'" :href="!itemA.value?'javascript:;':itemA.value"><img :src="itemA.icon" alt=""></a>
          <!-- <a v-if="item.media_data1" target="_blank" :href="item.iconVideoHref"><img :src="xiao_btn_img1" alt=""></a>
          <a v-if="item.media_data2" href="#stepImgId"><img :src="xiao_btn_img2" alt=""></a>
          <a v-if="item.media_data3" href="javascript:;"><img :src="xiao_btn_img3" alt=""></a> -->
        </div>
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-html="item.title"></div>
          <div style="color:red;font-weight: 700;" v-html="item.subtitle"></div>
           <!-- 地图 -->
          <!-- <listMap></listMap>
          <a href="http://uri.amap.com/navigation?from=114.02597366,22.54605355&to=114.029243,22.609562&mode=car&src=nyx_super;">高德地图</a> -->
          <hr>
          <div class="message_box">
            <div class="message_item"  v-html="item.content"></div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->

    <!-- 底部 -->
    <CBottom :lanPam="lang"></CBottom>
    <!-- <div class="btmBox">
      <p>Privacy Policy</p>
      - Copyright © Tripellet.com , All rights reserved -
    </div> -->

    <!-- top按钮 -->
      <el-backtop
      class="topBox"
      >
        <i class="el-icon-arrow-up"></i>
        <span>TOP</span>
      </el-backtop>
    <!-- <div class="topBox">
    </div> -->
  </div>
</template>
<script>
import { getProductList, getProductListTop } from "@/api/hfIndex";
import listMap from "./listMap.vue";
import newTitle from "./Component_title.vue";
import CBottom from "./Component_bottom.vue";
export default {
  components: {
    listMap,
    newTitle,
    CBottom
  },
  data() {
    return {
      id: "",
      paramsList: "",
      lang: "sc",
      usage: {
        sc: "使用方法",
        tc: "使用方法",
        en: "How to use",
        kr: "사용방법",
        jp: "使用方法",
      },
      topData: {},
      listData: {},
      // headImg: require('@/assets/images/hfkansai/zh/havefun_title-sc2.png'),
      loadImg: require("@/assets/images/hfkansai/loading.png"),
    };
  },
  created() {
    // this.id = this.$route.query.id;
    // this.lang = this.$route.query.l;
    // this.paramsList = this.$route.query.params;
    // console.log("created111111111", this.id, this.lang, this.paramsList);
    // // this.getTopDataFn(this.id, this.lang);
    // // this.getListDataFn(this.id, this.lang);
    // if (this.paramsList) {
    //   this.getListDataFn(this.id, this.lang, this.paramsList);
    // } else {
    //   this.getListDataFn(this.id, this.lang);
    // }
  },
  activated() {
    // console.log('要处理的逻辑')
    this.id = this.$route.query.id;
    this.lang = this.$route.query.l;
    this.paramsList = this.$route.query.params;
    // console.log('routeasdasdfasdasd',this.paramsList);
    console.log("created22222222222", this.id, this.lang, this.paramsList);
    if (this.paramsList) {
      this.getListDataFn(this.id, this.lang, JSON.parse(this.paramsList));
    } else {
      this.getListDataFn(this.id, this.lang);
    }
  },
  methods: {
    async getTopDataFn(id, lang) {
      let res = await getProductListTop({ id, lang });
      this.topData = res.data.data;
      console.log(res);
    },
    async getListDataFn(id, lang, pamList) {
      let res = null;
      console.log("getListDataFn", pamList);
      if (pamList) {
        res = await getProductList({ id, lang, page: 1, tag_ids: pamList });
      } else {
        res = await getProductList({ id, lang, page: 1 });
      }
      this.listData = res.data.data.list;
      console.log("this.listData", this.listData);
    },
  },
};
</script>
<style lang="scss" scoped>
.xiao_btn_img {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-top: 12px;
  a {
    height: unset;
    // flex: 1;
    width: 33%;
    // text-align: center;
    &:hover {
      border-bottom: 0;
    }
  }
  img {
    // flex: 1;
    width: 90%;
    max-width: 144px;
    height: auto;
    // height: 100%;
  }
}
/deep/.head_img_box {
  max-width: 766px;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  .img_box img,
  .loadImg,
  .neiron img,
  .title img {
    width: 100% !important;
    height: auto;
  }
}
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }

    .text_box {
      position: relative;
      margin-top: 12px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color: #ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}

.btmBox {
  margin: 0 auto;
  max-width: 766px;
  text-align: center;
  background-color: #e5e5e5;
  padding: 5.3333vw 0 7.7333vw;
  font-size: 2.6667vw;
  p {
    margin-bottom: 0.5333vw;
  }
}

.topBox{
  position: fixed;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  bottom: 18px !important;
  right: 4.2667vw !important;
  filter: drop-shadow(0 3px 4px #00000029);
  background-color: #fff;
  align-items: flex-start;
  color: #000;
  // display: flex;
  //   flex-direction: column;
    // align-content: center;
    // justify-content: center;
      text-align: center;
      .el-icon-arrow-up:before{
        font-size: 24px;
        font-weight: 700;
      }
      span{
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%);
      }
}


@media (min-width: 766px) {
  .btmBox {
    padding: 40px 0 58px;
    font-size: 20px;
    p {
      margin-bottom: 4px;
    }
  }
}
</style>