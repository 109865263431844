<template>
  <div>
    <!-- 底部按钮链接 -->
    <div class="btmImgBox" v-if="type == 'home'">
      <div class="btmImgBox-s">
        <img src="../../assets/images/havefun/tc_logo.png" alt="" />
      </div>
      <!--  -->
      <div v-if="newData.bottom_application" class="btmImgBox-z">
        <a
          v-if="newData.bottom_application[0]"
          target="_blank"
          :href="newData.bottom_application[0].jump_url"
        >
          <img :src="newData.bottom_application[0].image" alt="" />
        </a>
        <a
          v-if="newData.bottom_application[1]"
          target="_blank"
          :href="newData.bottom_application[1].jump_url"
        >
          <img :src="newData.bottom_application[1].image" alt="" />
        </a>
      </div>
      <!--  -->
      <div v-if="newData.bottom_media" class="btmImgBox-x">
        <a
          v-if="newData.bottom_media[0]"
          target="_blank"
          :href="newData.bottom_media[0].jump_url"
        >
          <img :src="newData.bottom_media[0].image" alt="" />
        </a>
        <a
          v-if="newData.bottom_media[1]"
          target="_blank"
          :href="newData.bottom_media[1].jump_url"
        >
          <img :src="newData.bottom_media[1].image" alt="" />
        </a>
        <a
          v-if="newData.bottom_media[2]"
          target="_blank"
          :href="newData.bottom_media[2].jump_url"
        >
          <img :src="newData.bottom_media[2].image" alt="" />
        </a>
      </div>
    </div>
    <!-- 底部 -->
    <div class="btmBox" v-html="newData.copyright"></div>
  </div>
</template>

<script>
import { getBottomSetup } from "@/api/hfIndex";
export default {
  data() {
    return {
      newData: [],
    };
  },
  created() {
    this.getBottomSetupFn();
  },
  methods: {
    async getBottomSetupFn() {
      let res = await getBottomSetup({ lang: this.lanPam });
      this.newData = res.data.data;
      console.log("底部", this.newData);
    },
  },
  props: ["lanPam", "type"],
};
</script>

<style lang="scss" scoped>
.btmImgBox {
  padding: 20px 0;
  background-color: #5a5a5a;
  .btmImgBox-s {
    text-align: center;
    margin-bottom: 14px;
    img {
      width: 290px;
    }
  }
  .btmImgBox-z {
    display: flex;
    justify-content: center;
    margin-bottom: 28px;
    img {
      width: 140px;
      height: 42px;
      margin: 0 5px;
      border-radius: 5px;
    }
  }

  .btmImgBox-x {
    display: flex;
    justify-content: center;
    img {
      border-radius: 50%;
      width: 38px;
      height: 38px;
      margin: 0 24px;
    }
  }
}
.btmBox {
  margin: 0 auto;
  max-width: 766px;
  text-align: center;
  background-color: #e5e5e5;
  padding: 5.3333vw 0 7.7333vw;
  font-size: 2.6667vw;
  p {
    margin-bottom: 0.5333vw;
  }
}

@media (min-width: 766px) {
  .btmBox {
    padding: 40px 0 58px;
    font-size: 20px;
    p {
      margin-bottom: 4px;
    }
  }
}
</style>