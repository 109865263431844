import request from '../utils/hfaxios'

export function getProductList(params) {
    return request({
        url: '/product/search',
        method: 'get',
        params
    })
}

export function getProductListTop(params) {
    return request({
        url: '/product/detail',
        method: 'get',
        params
    })
}

export function getBanner(params) {
    return request({
        url: `/banner/search?lang=${params}`,
        method: 'get',
    })
}

export function getProductList2(params) {
    return request({
        url: `/product/list?lang=${params}`,
        method: 'get',
    })
}

export function getProductStrategy(params) {
    return request({
        url: `/product/strategy`,
        method: 'get',
        params
    })
}

export function getProductService(params) {
    return request({
        url: `/product/service`,
        method: 'get',
        params
    })
}

export function getCommonSearchSelect(params) {
    return request({
        url: `/common/search-select`,
        method: 'get',
        params
    })
}

export function getProductStrategyDetails(params) {
    return request({
        url: `/product/strategy-details`,
        method: 'get',
        params
    })
}

export function getBottomSetup(params) {
    return request({
        url: `/banner/bottom-setup`,
        method: 'get',
        params
    })
}