import axios from 'axios'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_HF, // url = base url + request url
  // baseURL: "https://havefundemo.tripodeck.com/", // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // headers: {
  //   'Conten-type': 'multiper/form-data'
  // },
  timeout: 30000, // request timeout
  xhrFields:{
    withCredentials:true
    },
})

export default service
