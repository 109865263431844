<template>
  <div>
    <div class="titleBox">
      <i class="el-icon-arrow-left" @click="fhBtnFn"></i>
      <div v-if="txt" class="tit_Txt">{{ txt }}</div>
      <div v-else style="opacity: 0">1</div>
      <div v-if="type == 'list'" class="titleBox-right" @click="filterBtnFn">
        Filter
      </div>
      <slot name="CancelSelected"></slot>
    </div>
    <div class="titleBox titleBox-zw">1</div>
  </div>
</template>

<script>
export default {
  props: ["txt", "id", "type", "lang"],
  methods: {
    fhBtnFn() {
      console.log(this.type);
      if (this.type == "list") {
        this.$router.push(`/hfPage_nav?id=${this.id}`);
      } else if (this.type == "nav") {
        this.$router.push(`/hfPage_home`);
      } else {
        this.$router.back();
        // alert(1)
      }

      // else if (this.type == "filter") {
      //   this.$router.push(`/hfPage_list?id=${this.id}&l=${this.lang}`);
      // }
    },
    filterBtnFn() {
      this.$router.push(`/Filter?id=${this.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.titleBox {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  box-shadow: 0 3px 6px #a8a8a845;
  .el-icon-arrow-left {
    font-size: 22px;
    position: absolute;
    left: 16px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .titleBox-right {
    font-size: 14px;
    position: absolute;
    right: 16px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
  }
  .tit_Txt {
    height: 100%;
    font-size: 14px;
    color: #000;
  }

  &.titleBox-zw {
    position: relative;
    opacity: 0;
    z-index: 1;
  }
}
</style>
