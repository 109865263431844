/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

/**
 * 小数处理，保留n位
 * @param {*} num
 * @param {*} n
 */
export function toFixedDigit(num, n) {
  if (typeof num != 'number') {
    return false;
  }

  num = num.toString();
  var result = "";
  var zeroResult = function (n) {
    var zero = "";
    for (var i = 0; i < n; i++) {
      zero += "0";
    }
    return zero;
  }
  if (num % 1 == 0) {//整数
    result = num + "." + zeroResult(n);
  } else {//小数
    var num1 = num.split(".");
    if (num1[1].length < n) {
      result = num1[0] + "." + num1[1] + zeroResult(n - num1[1].length)
    } else {
      result = num1[0] + "." + num1[1].substring(0, n)
    }
  }
  return result;
}

import L from 'leaflet'
/**
 * 不同语言的地图处理
 * @param {*} newVal
 */
export function lanMap(obj, lan) {
  if (lan === 'zh') {
    obj.crs = L.CRS.Baidu
    obj.tms = true
    obj.url = 'http://maponline{s}.bdimg.com/onlinelabel/?qt=tile&x={x}&y={y}&z={z}&styles=pl&scaler=1&p=1&s=1'
    obj.attribution = '&copy; <a href="http://www.baidu.com">Baidu</a>'
    obj.subdomains = '0123'
  } else {
    obj.crs = L.CRS.EPSG3857
    obj.tms = false
    obj.url = 'http://www.google.jp/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}'
    obj.attribution = '&copy; <a href="http://www.google.jp">Google</a>'
  }
}

export function dealWeekDay(week) {
  let result = []
  let list = week.split(',')
  if (list.indexOf('1') > -1) {
    console.log(list.indexOf('1'))
    if (list.indexOf('2') == -1) {
      result.push('1')
    } else if (list.indexOf('2') > -1 && list.indexOf('3') == -1) {
      result.push('1-2')
    } else if (list.indexOf('2') > -1 && list.indexOf('3') > -1 && list.indexOf('4') == -1) {
      result.push('1-3')
    } else if (list.indexOf('2') > -1 && list.indexOf('3') > -1 && list.indexOf('4') > -1 && list.indexOf('5') == -1) {
      result.push('1-4')
    } else if (list.indexOf('2') > -1 && list.indexOf('3') > -1 && list.indexOf('4') > -1 && list.indexOf('5') > -1 && list.indexOf('6') == -1) {
      result.push('1-5')
    } else if (list.indexOf('2') > -1 && list.indexOf('3') > -1 && list.indexOf('4') > -1 && list.indexOf('5') > -1 && list.indexOf('6') > -1 && list.indexOf('7') == -1) {
      result.push('1-6')
    } else if (list.indexOf('2') > -1 && list.indexOf('3') > -1 && list.indexOf('4') > -1 && list.indexOf('5') > -1 && list.indexOf('6') > -1 && list.indexOf('7') > -1) {
      result.push('1-7')
    }
  }
  if (list.indexOf('1') == -1 && list.indexOf('2') > -1) {
    if (list.indexOf('3') == -1) {
      result.push('2')
    } else if (list.indexOf('3') > -1 && list.indexOf('4') == -1) {
      result.push('2-3')
    } else if (list.indexOf('3') > -1 && list.indexOf('4') > -1 &&  list.indexOf('5') == -1) {
      result.push('2-4')
    } else if (list.indexOf('3') > -1 && list.indexOf('4') > -1 &&  list.indexOf('5') > -1 &&  list.indexOf('6') == -1) {
      result.push('2-5')
    } else if (list.indexOf('3') > -1 && list.indexOf('4') > -1 &&  list.indexOf('5') > -1 &&  list.indexOf('6') > -1 && list.indexOf('7') == -1) {
      result.push('2-6')
    } else if (list.indexOf('3') > -1 && list.indexOf('4') > -1 &&  list.indexOf('5') > -1 &&  list.indexOf('6') > -1 && list.indexOf('7') > -1) {
      result.push('2-7')
    }
  }
  if (list.indexOf('2') == -1 &&list.indexOf('3') > -1) {
    if (list.indexOf('4') == -1) {
      result.push('3')
    } else if (list.indexOf('4') > -1 && list.indexOf('5') == -1) {
      result.push('3-4')
    } else if (list.indexOf('4') > -1 && list.indexOf('5') > -1 && list.indexOf('6') == -1) {
      result.push('3-5')
    } else if (list.indexOf('4') > -1 && list.indexOf('5') > -1 && list.indexOf('6') > -1 && list.indexOf('7') == -1) {
      result.push('3-6')
    } else if (list.indexOf('4') > -1 && list.indexOf('5') > -1 && list.indexOf('6') > -1 && list.indexOf('7') > -1) {
      result.push('3-7')
    }
  }
  if (list.indexOf('3') == -1 && list.indexOf('4') > -1) {
    if (list.indexOf('5') == -1) {
      result.push('4')
    } else if (list.indexOf('5') > -1 && list.indexOf('6') == -1) {
      result.push('4-5')
    } else if (list.indexOf('5') > -1 && list.indexOf('6') > -1 && list.indexOf('7') == -1) {
      result.push('4-6')
    } else if (list.indexOf('5') > -1 && list.indexOf('6') > -1 && list.indexOf('7') > -1) {
      result.push('4-7')
    }
  }
  if (list.indexOf('4') == -1 && list.indexOf('5') > -1) {
    if (list.indexOf('6') == -1) {
      result.push('5')
    } else if (list.indexOf('6') > -1 && list.indexOf('7') == -1) {
      result.push('5-6')
    } else if (list.indexOf('6') > -1 && list.indexOf('7') > -1) {
      result.push('5-7')
    }
  }
  if (list.indexOf('5') == -1 && list.indexOf('6') > -1) {
    if (list.indexOf('7') == -1) {
      result.push('6')
    } else if (list.indexOf('7') > -1) {
      result.push('6-7')
    }
  }
  if (list.indexOf('6') == -1 && list.indexOf('7') > -1) {
    result.push('7')
  }
  if (list.indexOf('10') > -1) {
    result.push('10')
  }
  if (list.indexOf('11') > -1) {
    result.push('11')
  }
  if (list.indexOf('12') > -1) {
    result.push('12')
  }
  if (list.indexOf('13') > -1) {
    result.push('13')
  }
  if (list.indexOf('14') > -1) {
    result.push('14')
  }
  if (list.indexOf('15') > -1) {
    result.push('15')
  }
  return result
}