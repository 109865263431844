<template>
  <div class="right-box">
    <!-- 在原来位置，不会随着一起滚动的 -->
    <div class="right-content-box">
      <div class="right-aside">
        <!-- <div class="aside-title">{{ $t('店铺地址') }}</div> -->
        <!-- <div class="aside-address"><span v-if="detailData.address" class="address-text">{{ detailData.address }}</span><span v-else>-</span></div> -->
        <l-map
          v-if="mapShow"
          class="aside-map"
          :zoom="zoom"
          :center="center"
          :crs="crs"
          @update:zoom="zoomUpdated"
          @update:center="centerUpdated"
          @update:bounds="boundsUpdated"
        >
          <l-tile-layer
            :url="url"
            :subdomains="subdomains"
            :options="tileLayerOptions"
            :tms="tms"
          ></l-tile-layer>
          <l-marker
            :lat-lng="marker"
            :icon="addressIcon"
            :attribution="attribution"
          >
            <l-popup>{{ detailData.storeName }}</l-popup>
          </l-marker>
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>
import { lanMap } from "@/utils/util";
// import { getLanguage } from '@/lang/index'
import { latLng, icon } from "leaflet";
// LIcon,
import L from "leaflet";
import "proj4";
import "proj4leaflet";
import "leaflet.chinatmsproviders";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
export default {
  components: { LMap, LTileLayer, LMarker, LPopup },

  data() {
    return {
      detailData: {
        longitude: 113.390031,
        latitude: 23.126711,
        address:
          "〒542-0075大阪市中央區難波千日前11番6號　なんばグランド花月內1F",
        storeName:
          "しゃぶ笑 なんばグランド花月店 (Shabuwara Namba Grand Kagetsu Shop)",
        storeName:
          "しゃぶ笑 なんばグランド花月店 (Shabuwara Namba Grand Kagetsu Shop)",
      },
      center: { lng: 113.390031, lat: 23.126711 },
      zooms: 15,
      ins: require("@/assets/images/media-03.png"),
      tictok: require("@/assets/images/media-04.png"),
      dataLoading: false,
      BMap: null,
      map: null,
      thumbsSwiper: {},
      swiper: {},
      zoom: 14,
      mapShow: false,
      lang: "",
      url: "http://www.google.jp/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}",
      attribution: '&copy; <a href="http://www.google.jp">Google</a>',
      subdomains: "",
      crs: L.CRS.EPSG3857,
      tms: false,
      tileLayerOptions: {
        maxZoom: 18,
        minZoom: 8,
      },
      marker: [],
      addressIcon: icon({
        iconUrl: require("@/assets/images/address.png"),
        iconSize: [32, 32],
        iconAnchor: [16, 10],
      }),
    };
  },
  watch: {
    center: {
      handler(newVal) {
        // console.log(newVal)
      },
      deep: true,
    },
    lan(newVal) {
      lanMap(this, newVal);
    },
  },
  created() {
    this.init();
    lanMap(this, this.lan);
    this.lang = localStorage.getItem("locale");
    return localStorage.getItem("locale");
  },
  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    init() {
      // console.log(data);
      // this.detailData = data

      if (this.detailData.latitude && this.detailData.longitude) {
        this.mapShow = true;
      }
      this.center = latLng(this.detailData.latitude, this.detailData.longitude);
      this.marker = [this.detailData.latitude, this.detailData.longitude];
    },
  },
};
</script>
<style lang="scss" scoped>
.right-aside {
  background: #fff;
  margin-top: 20px;
  //   padding: 10px;
  width: 100%;
  -moz-box-shadow: 1px 1px 5px #e0e3e2;
  -webkit-box-shadow: 1px 1px 5px #e0e3e2;
  box-shadow: 1px 1px 5px #e0e3e2;
  .aside-map {
    width: 100%;
    height: 200px;
  }
  .aside-title {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 600;
    color: #2d333f;
  }
  .aside-Announcement {
    margin-bottom: 10px;
  }
  .aside-payType {
    line-height: 20px;
    font-size: 14px;
  }
  .aside-phone {
    font-size: 16px;
    color: #e7b530;
  }
}
</style>
